*
{
  margin: 0;
  -webkit-user-select: none;
  user-select: none;
}

/*experimental*/
/*noinspection CssInvalidPropertyValue,CssUnknownProperty*/
body
{
  font-smooth: always;
  -moz-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
}

/* to fix login form select issue in safari */
textarea, input, input:before, input:after
{
  -moz-user-select: initial;
  -ms-user-select: initial;
  -webkit-user-select: initial;
  user-select: initial;
}

/* single page application */
body
{
  background-color: #f5f5f5;
}

/* to fix input filed background color issue when autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  transition: background-color 5000s ease-in-out 0s;
}
